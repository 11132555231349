<template>
  <div>
    <ElementForm
      ref="newInvitationForm"
      label-position="top"
      class="add-form"
      :model="newInvitationForm"
      :rules="validationRules"
      hide-required-asterisk
    >
      <ElementFormItem
        label="Email Address"
        prop="inviteeList"
        class="add-form__item"
      >
        <div class="email-editor el-input__inner">
          <div class="email-editor__emails">
            <ElementTag
              v-for="(invitee, inviteeIndex) in newInvitationForm.inviteeList"
              :key="inviteeIndex"
              closable
              size="mini"
              :type="invitee.isValid ? '' : 'danger'"
              class="email-editor__email"
              @close="removeInvitee(invitee)"
            >
              {{ invitee.email }}
            </ElementTag>
            <input
              ref="editorEmailField"
              v-model.trim="editorEmail"
              class="email-editor__input"
              @keydown.backspace.stop="removeLastInvitee"
              @keydown.enter.prevent.stop="tokenizeNewEmail"
              @keydown.space.prevent.stop="tokenizeNewEmail"
              @keydown.tab.prevent.stop="tokenizeNewEmail"
              @keydown.comma.prevent.stop="tokenizeNewEmail"
              @paste.prevent.stop="handlePaste"
            >
          </div>
          <ElementButton
            v-if="editorEmail !== ''"
            type="text"
            class="email-editor__add el-input__icon"
            @click="tokenizeNewEmail"
          >
            <i class="el-icon-plus" />
          </ElementButton>
        </div>
      </ElementFormItem>

      <ElementFormItem
        label="Role"
        prop="roleId"
        class="add-form__item"
      >
        <ElementSelect
          v-model="newInvitationForm.roleId"
          placeholder="Select a role"
        >
          <ElementOption
            v-for="role in roles"
            :key="role.id"
            :label="role.display_name"
            :value="role.id"
          />
        </ElementSelect>
      </ElementFormItem>

      <ElementFormItem
        placeholder="Select teams"
        label="Teams"
        prop="teamsList"
        class="add-form__item"
      >
        <ElementSelect
          v-model="newInvitationForm.teamsList"
          placeholder="Select a team"
          multiple
          filterable
        >
          <ElementOption
            v-for="team in teams"
            :key="team.id"
            :label="team.name"
            :value="team.id"
          />
        </ElementSelect>
      </ElementFormItem>
    </ElementForm>
    <div
      slot="footer"
      class="add-form__buttons"
    >
      <ElementButton
        plain
        class="add-form__cancel"
        @click="closeNewInvitationForm"
      >
        Cancel
      </ElementButton>
      <ElementButton
        type="primary"
        plain
        class="add-form__submit"
        @click="submitForm"
      >
        Done
      </ElementButton>
    </div>
  </div>
</template>

<script>
// https://emailregex.com/
/* eslint-disable no-useless-escape -- This regex expression inevitably breaks some eslint rules, so we need to disable it for this specific case. */
import { mapActions, mapGetters } from 'vuex'

const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/* eslint-enable no-useless-escape -- enabling rule */
export default {
  name: 'AddUserForm',

  data () {
    return {
      selectedRoleId: null,
      searchText: '',
      editorEmail: '',
      isLoadingTeams: false,
      newInvitationForm: {
        inviteeList: [],
        teamsList: [],
        roleId: null
      },
      validationRules: {
        inviteeList: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback()
              } else {
                callback(new Error('Please provide at least one email address'))
              }
            },
            trigger: ['blur', 'change']
          },
          {
            validator: this.validatorInviteeList
          }
        ],
        roleId: [
          {
            required: true,
            message: 'Please choose a role',
            trigger: ['blur', 'change']
          }
        ],
        teamsList: [
          {
            required: true,
            message: 'Please choose at least one team',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      roles: 'getRoles',
      teams: 'getTeams'
    })
  },

  async created () {
    this.isLoadingTeams = true

    try {
      await this.fetchTeams()
    } catch (error) {
      if (error.response) {
        // this.errorStatus = error.response.status
        console.log('Error fetching the teams')
      } else {
        throw error
      }
    }

    this.isLoadingTeams = false
  },

  methods: {
    ...mapActions(['addUsers', 'fetchTeams']),

    /**
     * @param {Event} event
     */
    removeLastInvitee (event) {
      if (event.target.value === '') {
        event.preventDefault()

        const inviteeList = this.newInvitationForm.inviteeList

        if (inviteeList.length > 0) {
          this.removeInvitee(inviteeList[inviteeList.length - 1])
        }
      }
    },

    /**
     * @param {object} invitee
     */
    removeInvitee (invitee) {
      const index = this.newInvitationForm.inviteeList.findIndex(
        inviteeFromList => inviteeFromList.email === invitee.email
      )

      if (index > -1) {
        this.newInvitationForm.inviteeList.splice(index, 1)
      }

      this.validateInviteeListField()
      this.focusEmailField()
    },

    /**
     */
    validateInviteeListField () {
      this.$refs.newInvitationForm.validateField('inviteeList')
    },

    /**
     */
    focusEmailField () {
      this.$nextTick().then(() => {
        this.$refs.editorEmailField.focus()
      })
    },

    /**
     * @param   {object[]} invitations
     * @returns {boolean}
     */
    async createInvitations (invitations) {
      try {
        await this.addUsers({
          invitations
        })

        this.newInviteeList = []

        return true
      } catch (error) {
        const errorEmails = this.getEmailsFromError(error)

        invitations.forEach(invitation => {
          invitation.isValid = !errorEmails.includes(invitation.email)
        })

        this.newInviteeList = invitations

        return false
      }
    },

    /**
     *
     */
    async submitForm () {
      this.tokenizeNewEmail()

      await this.$refs.newInvitationForm.validate(async isValid => {
        if (!isValid) {
          return
        }

        const invitations = this.newInvitationForm.inviteeList.map(invitee => ({
          email: invitee.email,
          roleId: this.newInvitationForm.roleId,
          teamIds: this.newInvitationForm.teamsList
        }))

        const invitationsSuccessful = await this.createInvitations(invitations)

        if (invitationsSuccessful) {
          this.closeNewInvitationForm()
        }
      })
    },

    /**
     */
    resetFormFields () {
      this.$refs.newInvitationForm.resetFields()
      this.$emit('close-invitation-form')
    },

    /**
     */
    closeNewInvitationForm () {
      this.$refs.newInvitationForm.resetFields()
      this.$emit('close-invitation-form')
    },

    /**
     * @param {object}   rule
     * @param {object[]} value
     * @param {Function} callback
     */
    validatorInviteeList (rule, value, callback) {
      const uniqueInvitees = {}

      value.forEach(invitee => {
        const email = invitee.email.toLowerCase()

        if (new RegExp(REGEX_EMAIL).test(email)) {
          invitee.isValid = true
        } else {
          invitee.isValid = false
        }

        if (uniqueInvitees[email]) {
          invitee.isValid = false
        } else {
          uniqueInvitees[email] = true
        }
      })

      if (value.every(invitee => invitee.isValid)) {
        callback()
      } else {
        callback(
          new Error(
            'Please provide valid email addresses. No duplicates are allowed.'
          )
        )
      }
    },

    /**
     */
    tokenizeNewEmail () {
      const pendingInput = this.editorEmail

      if (pendingInput !== '') {
        this.newInvitationForm.inviteeList.push({
          email: pendingInput,
          isValid: true
        })
        this.editorEmail = ''
      }

      this.validateInviteeListField()
      this.focusEmailField()
    },

    /**
     * @param {ClipboardEvent} pasteEvent
     */
    handlePaste (pasteEvent) {
      const newInvitees = this.splitPasteContent(
        pasteEvent.clipboardData.getData('Text')
      ).map(email => ({ email, isValid: true }))

      this.newInvitationForm.inviteeList.push(...newInvitees)
      this.validateInviteeListField()
      this.focusEmailField()
    },

    /**
     * @param   {string}   pasteContent
     *
     * @returns {string[]}
     */
    splitPasteContent (pasteContent) {
      let parts

      if (!pasteContent) {
        return []
      }

      const pasteContentTrimmed = pasteContent.trim()
      const regexes = [
        /,\s*/g, // comma with optional spaces after
        /\s+/g // spaces
      ]

      for (const regex of regexes) {
        const trimParts = pasteContentTrimmed
          .split(regex)
          .filter(part => part.length > 0)
        parts = trimParts.map(email => email.trim())

        if (parts.length > 0 && parts[0] !== pasteContentTrimmed) {
          return parts
        }
      }

      return parts
    }
  }
}
</script>

<style lang="scss" scoped>
$max-height-editor: 300px;
$gap-emails: spacing(1/2);
$top-email-tag-close: 1px;
$padding-email-add-button: 9px 6px;

.add-form {
  width: spacing(50);
}

.add-form__item {
  /deep/ .el-form-item__label {
    padding: 0;
  }

  /deep/ .el-select {
    width: 100%;
  }
}

.add-form__buttons {
  text-align: center;
}

.email-editor {
  display: flex;
  justify-content: space-between;
  height: auto;
  min-height: spacing(5);
  max-height: $max-height-editor;
  padding: $gap-emails;
}

.email-editor__emails {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  overflow: auto;
  border: $gap-emails solid transparent;
}

.email-editor__email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: 0 spacing(1) spacing(1) 0;

  /deep/ .el-tag__close {
    top: $top-email-tag-close;
  }
}

.email-editor__input {
  display: flex;
  flex-shrink: 1;
  width: 100%;
  padding: spacing(1/2) 0;
  font-size: inherit;
}

.email-editor__add {
  position: absolute;
  top: spacing(1);
  right: spacing(1/2);
  width: auto;
  height: fit-content;
  padding: $padding-email-add-button;
  background: $white;
}
</style>
