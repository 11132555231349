<template>
  <div>
    <div class="users-table--actions">
      <div class="users-table-teams__total">
        <span v-if="usersCount === 1">{{ usersCount }} user</span>
        <span v-else-if="usersCount > 1">{{ usersCount }} users</span>
        <span v-else>no users</span>
      </div>

      <ElementInput
        ref="searchInput"
        v-model="query"
        placeholder="Search"
        size="small"
        clearable
        class="users-table-teams__search"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
        />
      </ElementInput>
      <ElementButton
        type="primary"
        plain
        size="small"
        icon="el-icon-plus"
        class="users-table--add-user-button"
        @click="handleUserFormVisibility"
      >
        Add Users
      </ElementButton>
      <ElementButton
        type="primary"
        plain
        size="small"
        icon="el-icon-download"
        class="users-table--add-user-button"
        title="Excel export"
        :loading="isExportingUsers"
        @click="handleUsersExport"
      />
    </div>

    <ElementTable
      v-loading="isLoadingUsers"
      :data="usersData"
      class="users-table--table"
      empty-text="Users not found."
      border
      stripe
    >
      <ElementTableColumn
        label="Email"
        prop="email"
        sortable
        width="240"
        empty-text="There's no users available."
      />

      <ElementTableColumn
        label="User Type"
        prop="isHubAdmin"
        sortable
        width="150"
      >
        <template slot-scope="scope">
          <ElementTag
            :type="
              scope.row.isStaff
                ? 'danger'
                : scope.row.isHubAdmin
                  ? 'warning'
                  : 'info'
            "
            size="mini"
            disable-transitions
          >
            {{
              scope.row.isStaff
                ? 'Staff'
                : scope.row.isHubAdmin
                  ? 'Hub Admin'
                  : 'User'
            }}
          </ElementTag>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Status"
        prop="isActive"
        sortable
        width="150"
      >
        <template slot-scope="scope">
          <template v-if="edit !== scope.row.id">
            <ElementTag
              :type="scope.row.isActive ? 'success' : 'danger'"
              size="mini"
              disable-transitions
            >
              {{ scope.row.isActive ? 'Active' : 'Inactive' }}
            </ElementTag>
          </template>
          <ElementSelect
            v-else
            v-model="userModel.isActive"
            size="mini"
          >
            <ElementOption
              v-for="item in USER_ACTIVITY"
              :key="`${item.label}`"
              :label="item.label"
              :value="item.value"
            />
          </ElementSelect>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Role"
        prop="role"
        sortable
        width="150"
      >
        <template slot-scope="scope">
          <template v-if="edit !== scope.row.id">
            {{ scope.row.role.display_name }}
          </template>
          <ElementSelect
            v-else
            v-model="userModel.roleId"
            size="mini"
          >
            <ElementOption
              v-for="role in roles"
              :key="role.id"
              :label="role.display_name"
              :value="role.id"
            />
          </ElementSelect>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Team(s)"
        prop="teams"
        sortable
        width="250"
      >
        <template slot-scope="scope">
          <p
            v-if="edit === scope.row.id"
            class="users-table--teams users-table--teams-hoverable"
            @click="handleAssignTeamFormVisibility(scope.row.id)"
          >
            {{ formatTeams(scope.row.teams) }}
            <i class="el-icon-edit" />
          </p>
          <p
            v-if="edit !== scope.row.id"
            class="users-table--teams"
          >
            {{ formatTeams(scope.row.teams) }}
          </p>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Last Login"
        prop="lastLogin"
        sortable
        width="170"
      />
      <ElementTableColumn
        label="Date Created"
        prop="dateJoined"
        sortable
        width="170"
      />

      <ElementTableColumn
        align="center"
        width="150"
      >
        <template slot="header">
          Actions
        </template>
        <template slot-scope="scope">
          <ElementButton
            v-if="edit !== scope.row.id"
            :disabled="
              (edit && edit !== scope.row.id) ||
                scope.row.isHubAdmin ||
                scope.row.isStaff
            "
            type="text"
            size="mini"
            class="email-editor__add el-input__icon"
            @click="onEdit(scope.row.id)"
          >
            <i class="el-icon-edit" />
          </ElementButton>
          <ElementButton
            v-else
            type="text"
            size="mini"
            class="email-editor__add el-input__icon"
            @click="onSave(scope.row.id)"
          >
            <i class="el-icon-check" />
          </ElementButton>
          <ElementButton
            v-if="edit === scope.row.id"
            type="text"
            size="mini"
            @click="onCancel"
          >
            <i class="el-icon-close" />
          </ElementButton>
          <ElementButton
            v-else
            :disabled="
              (edit && edit !== scope.row.id) ||
                scope.row.isHubAdmin ||
                scope.row.isStaff
            "
            type="text"
            size="mini"
            @click="onDelete(scope.row.id)"
          >
            <i class="el-icon-delete" />
          </ElementButton>
        </template>
      </ElementTableColumn>
    </ElementTable>

    <ElementPagination
      align="right"
      layout="prev, pager, next"
      :total="usersCount"
      :per-page="limit"
      :current-page="currentPage"
      @current-change="setPage"
    />

    <StitchDialog
      title="Assign Teams"
      :visible.sync="showAssignTeamsForm"
      :show-close="true"
      append-to-body
      center
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      class="assign-teams__dialog"
    >
      <AssignTeamsForm
        :user-teams="userTeamsModel"
        :user-id="userIdToAssign"
        @close-assign-teams-form="handleAssignTeamFormVisibility"
      />
    </StitchDialog>

    <StitchDialog
      title="Invite new users"
      :visible.sync="showNewInvitationForm"
      :show-close="true"
      append-to-body
      center
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      class="add-new-invitation__dialog"
    >
      <AddUserForm @close-invitation-form="handleUserFormVisibility" />
    </StitchDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { USER_ACTIVITY, USER_ROLE } from '@/constants/roleType'
import AddUserForm from './AddUserForm.vue'
import AssignTeamsForm from './AssignTeamsForm.vue'

export default {
  name: 'UsersTable',

  components: {
    AddUserForm,
    AssignTeamsForm
  },

  data () {
    return {
      USER_ACTIVITY,
      USER_ROLE,
      edit: null,
      query: '',
      isLoadingUsers: false,
      isExportingUsers: false,
      showActiveSelect: false,
      showTeamsModal: false,
      showNewInvitationForm: false,
      showAssignTeamsForm: false,
      userTeamsModel: [],
      userIdToAssign: null,
      currentPage: 1,
      userModel: {
        userId: null,
        email: null,
        isActive: false,
        roleId: null
      }
    }
  },

  computed: {
    ...mapGetters({
      roles: 'getRoles',
      users: 'getUsers',
      usersCount: 'getUsersCount',
      limit: 'getLimit',
      activeUser: 'getCognitoUserData'
    }),

    /**
     * @returns {object[]}
     */
    usersData () {
      return this.users
    }
  },

  watch: {
    /**
     */
    async query () {
      this.isLoadingUsers = true
      await this.fetchUsers({ query: this.query })
      this.currentPage = 1
      this.isLoadingUsers = false
    }
  },

  async created () {
    this.isLoadingUsers = true

    try {
      await this.fetchRoles()
      await this.fetchUsers({ page: 1 })
    } catch (error) {
      if (error.response) {
        // this.errorStatus = error.response.status
        console.log('Error fetching the users')
      } else {
        throw error
      }
    }

    this.isLoadingUsers = false
  },

  methods: {
    ...mapActions([
      'fetchUsers',
      'updateUser',
      'deleteUser',
      'fetchRoles',
      'exportUsers'
    ]),

    /**
     */
    async handleUsersExport () {
      this.isExportingUsers = true
      await this.exportUsers()
      this.isExportingUsers = false
    },

    /**
     *
     */
    handleUserFormVisibility () {
      this.showNewInvitationForm = !this.showNewInvitationForm
    },

    /**
     * @param {number} userId
     */
    handleAssignTeamFormVisibility (userId) {
      if (userId) {
        this.userIdToAssign = userId
        this.userTeamsModel = this.users.find(user => user.id === userId).teams
      }

      this.showAssignTeamsForm = !this.showAssignTeamsForm

      if (!this.showAssignTeamsForm) {
        this.edit = null
      }
    },

    /**
     * @param {number} userId
     */
    onEdit (userId) {
      const userToEdit = this.users.find(user => user.id === userId)

      this.userModel = {
        userId: userId,
        email: userToEdit.email,
        isActive: userToEdit.isActive,
        roleId: userToEdit.role.id
      }

      this.edit = this.edit !== userId ? userId : null
    },

    /**
     *
     */
    async onSave () {
      this.isLoadingUsers = true
      await this.updateUser({
        query: this.query,
        page: this.currentPage,
        ...this.userModel
      })
      this.onCancel()
      this.isLoadingUsers = false
    },

    /**
     *
     */
    onCancel () {
      this.userModel = {
        userId: null,
        email: null,
        isActive: null,
        roleId: null
      }

      this.edit = null
    },

    /**
     * @param {number} userId
     */
    onDelete (userId) {
      const userToDelete = this.users.find(user => user.id === userId)

      this.$confirm(
        `Are you sure you want delete the user "${userToDelete.email}"?`,
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          showClose: false
        }
      )
        .then(async () => {
          await this.deleteUser({ userId })
        })
        .catch(() => {})
    },

    /**
     * @param   {Array}    teams
     *
     * @returns { string }
     */
    formatTeams (teams) {
      if (teams.length === 0) {
        return '-'
      }

      const teamsLimit = 3
      const teamsToDisplay = teams.slice(0, teamsLimit)
      const extraTeamsCount = teams.length - teamsLimit
      let teamsString = teamsToDisplay.map(team => team.name).join(', ')

      if (extraTeamsCount > 0) {
        teamsString += ` and ${extraTeamsCount} more...`
      }

      return teamsString
    },

    /**
     *
     * @param { number } page
     */
    async setPage (page) {
      this.isLoadingUsers = true
      await this.fetchUsers({ page, query: this.query })
      this.currentPage = page
      this.isLoadingUsers = false
    }
  }
}
</script>

<style lang="scss" scoped>
$users-table-teams-column-width: 250px;
$content-header-search-width: 210px;
$content-header-input-min-width: spacing(12);
$button-small-padding: 10px 15px;
$title-top: 12px;
$total-members-padding1: 8px;
$total-members-padding2: 15px;
$total-members-border-radius: 8px;
$total-members-font-size: 14px;
$total-members-text-color: #909399;
$total-members-bg-color: #f0f2f5;

.users-table--table {
  width: 100%;
  margin-top: spacing(3);
}

.users-table--teams {
  max-width: $users-table-teams-column-width;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  word-break: normal;
}

.users-table__action-edit {
  display: inline;
  color: $blue;
}

.users-table__action-delete {
  display: inline;
  color: $red;
}

.users-table--actions {
  position: absolute;
  top: spacing(4);
  right: spacing(2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.add-new-invitation__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.assign-teams__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.users-table--teams-hoverable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.users-table-teams__total {
  margin-top: spacing(5);
  margin-left: spacing(3);
  padding: $total-members-padding1 $total-members-padding2;
  color: $total-members-text-color;
  font-size: $total-members-font-size;
}

.users-table-teams__search {
  width: auto;
  max-width: $content-header-search-width;
  margin-right: spacing(1);
  margin-left: auto;

  @media (min-width: $media-query-m) {
    min-width: $content-header-input-min-width;
  }
}
</style>

<style lang="scss">
.users-table--table .el-input--suffix .el-input__inner {
  height: spacing(3.8);
}
</style>
